const API_URL = "https://api.speakup.tcup.co.uk";

export default {
    DEFAULT_REGION: "eu-west-2",
    cognito: {
        USER_POOL_ID: "eu-west-2_4VpA8wyOc",
        APP_CLIENT_ID: "2tcqaeac7t51v1c97v3k5mh3jm",
        IDENTITY_POOL_ID: "eu-west-2:43f3509d-a63a-4b5d-ab7b-b1e9c29bfd6e"
    },
    s3: {
        BUCKET: "tcup-prod-app-uploads"
    },
    apis: {
        usersApi: {
            URL: `${API_URL}/users-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        },
        commentsApi: {
            URL: `${API_URL}/comments-service`
        }
    }
};